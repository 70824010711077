import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import fontawesome from '@fortawesome/fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid'

library.add(fab, faCheckSquare, faCoffee)


export default ({ icon, title, description }) => (

	<CardContainer className="col-3 col-6-t col-12-m">
		<IconFeatureHeader>
	    	<StyledIcon icon={icon} />
	    	<IconTitle>{title}</IconTitle>
    	</IconFeatureHeader>
    	<IconDescription>{description}</IconDescription>
    </CardContainer>
);

// Styled components

const CardContainer = styled.div `
	@media (max-width: 768px) {
		margin-bottom: 24px;
	}
`

const StyledIcon = styled(props => <FontAwesomeIcon {...props} />) `
	font-size: 24px;
	max-width: 24px;
`;

const IconFeatureHeader = styled.div `
	display: flex;
	align-items: center;
	margin-bottom: 24px;
`

const IconTitle = styled.h4 `
	padding-left: 12px;
	margin-top: 0;
	margin-bottom: 0;
`

const IconDescription = styled.p `
	margin-bottom: 0;
`