import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import fontawesome from '@fortawesome/fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fab)

// listItem

export default ({ listItem }) => (

	<CheckListItem>
		<IconListItem>
	    	<StyledIcon icon="check-circle" />
	    	<IconDescription>{listItem}</IconDescription>
    	</IconListItem>
    </CheckListItem>
);

// Styled components

const CheckListItem = styled.li `
	width: 50%;

	@media (max-width: 1040px) {
		width: 100%;
	}
`

const StyledIcon = styled(props => <FontAwesomeIcon {...props} />) `
	font-size: 24px;
	max-width: 24px;
`;

const IconListItem = styled.div `
	display: flex;
	align-items: center;
	margin-bottom: 24px;
`

const IconDescription = styled.p `
	margin-bottom: 0;
	padding-left: 12px;
	color: #fff;
`